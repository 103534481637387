@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  font-family: 'Open Sans', sans-serif;
  overflow-x: hidden;
}

.slide-container-horizontal {
  max-width: 0;
  overflow: hidden;
  transition: max-width 0.5s ease-in-out;
}

.slide-right {
  max-width: 700px;
  /* Adjust the desired max width of the container */
}

.slide-left {
  max-width: 0;
}


.slide-container {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.6s ease-in-out;
}

.slide-down {
  max-height: 700px;
  /* Adjust the desired max height of the container */
}

.slide-up {
  max-height: 0;
}