.swiper-button-next, .swiper-button-prev {
  color: #5BC02B;
}

.blogswiper {
  overflow-y: visible;
}

.blogslide.swiper-slide-active {
  transform: scale(1.3);
  z-index: 100;
  transition: transform ease-in-out 0.3s;
}
@media screen and (max-width: 768px)  {
  .blogslide.swiper-slide-active {
    transform: scale(1.1);
    z-index: 100;
    transition: transform ease-in-out 0.3s;
  }
}

.blogswiper .swiper-button-next, .blogswiper .swiper-button-prev {
  position: absolute;
  top: 30%;
  border: #5BC02B solid 2px;
  border-radius: 100%;
  padding: 28px;
}

