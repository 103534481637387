  /* estilos do carrossel mudar tbm p outro arquivo */

  .glide__slide {
    height: 200px;
    overflow: hidden;
  }

  .glide__bullet[class*="active"] {
    background-color: white;
  }


  @media screen and (min-width: 1501px) {
    .glide__slide {
      height: 260px;
      overflow: hidden;
    }
  }