.fade-in {
  opacity: 0;
}

.fade-in-animation {
  opacity: 1;
  animation: fadeIn 3s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}